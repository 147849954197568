import React from 'react';
import './App.css';
import { Row, Col, Typography, Divider } from 'antd';
import business from './business.json';

const { Title } = Typography;

function App() {
  return (
    <div className="App">
      <Row justify={'center'}>
        <Col span={20}>
          <Row>
            <Col style={{ marginBottom: "50px" }} span={24}>
              <Title style={{ fontFamily: 'monospace'}}>Gaurav Thaman</Title>
              <Title style={{ fontFamily: 'monospace'}} level={5}>BTech In Mechanical Engineering</Title>
              <div style={{ fontFamily: 'monospace'}}>
                <a style={{ textDecoration: 'underline' }} href='mailto:gauravthaman@hotmail.com'>gauravthaman@hotmail.com</a>
              </div>
              <br/>
              <div style={{ fontFamily: 'monospace'}}>
                <a href='tel:+919876788767'>+91-9876788767</a> &nbsp;
                <a href='tel:+918054809786'>+91-8054809786</a>
              </div>
            </Col>

            <Col span={24} style={{ fontFamily: 'monospace'}}>
              {
                business.map(obj => {
                  let details = Object.keys(obj).map((key, i) => {
                    return (
                      <Col style={{ marginBottom: "15px" }} xs={24} md={18} >
                        <Row justify={'space-between'}>
                          <Col style={{ textAlign: "left", fontFamily: 'monospace' }} span={6} ><b>{key}</b></Col>
                          <Col span={2}>:</Col>
                          <Col style={{ textAlign: "left", fontFamily: 'monospace' }} span={6}>{obj[key]}</Col>
                        </Row>
                        {
                          i === Object.keys(obj).length - 1 && (
                            <Divider style={{ color: 'black' }} />
                          )
                        }
                      </Col>
                    );
                  });
                  return <Row justify={'center'}>{details}</Row>
                })
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default App;
